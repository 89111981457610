import { format, formatDistance, parseISO, sub } from 'date-fns';

export const capitalize = (str: string | null): string | null => {
  if (!str) return null;
  if (str.length === 0) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1);
};

export const titleCase = (str: string): string => {
  if (str.length === 0) {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1);
};

export const toHumanReadable = (str: string | null): string | null => {
  if (!str) return null;

  const sanitizedStr = str
    .replace(/_/g, ' ')
    .replace(/-/g, ' ')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase();

  return capitalize(sanitizedStr);
};

export const lowerCaseTrim = (str: string) => {
  return str.toLowerCase().trim();
};

export const maskEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const maskedName = name.slice(0, 2) + '***' + name.slice(-2);
  return maskedName + '@' + domain;
};

export const formatCurrency = (amount: number) => {
  const pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  return `${pounds.format(amount)}`;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const objectToString = (obj: {
  [x: string]: any;
  hasOwnProperty: (arg0: string) => any;
}) => {
  const keyValuePairs = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const keyValueString = `${key}: ${obj[key]}`;
      keyValuePairs.push(keyValueString);
    }
  }

  return keyValuePairs.join(', ');
};

export const calculateDateFromNow = (date: string) => {
  return formatDistance(new Date(date), new Date());
};

export const weeksAgo = (weeks: number) => {
  const result = sub(new Date(), {
    weeks: weeks,
  });
  return format(toLocalTimezone(result), 'yyyy-MM-dd');
};

export const prettyDateUKStyle = (date: any) => {
  const dateObject = parseISO(date);

  return format(toLocalTimezone(dateObject), 'do MMMM yyyy');
};

export const prettyDateShort = (date: any) => {
  const dateObject = parseISO(date);

  return format(dateObject, 'dd/MM/yyyy');
};

export const prettyDateShortWithTime = (date: any) => {
  const dateObject = parseISO(date);

  return format(toLocalTimezone(dateObject), 'dd/MM/yyyy HH:mm');
};

export const toLocalTimezone = (utcDate: Date) => {
  const offsetMinutes = utcDate.getTimezoneOffset();
  const localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);

  return localTime;
};

export const isValidDateString = (dateStr: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  return regex.test(dateStr);
};

export const getInitials = (fullName: string) => {
  if (!fullName) return null;
  return fullName
    .split(' ')
    .slice(0, 2)
    .map((word) => word[0]?.toUpperCase())
    .join('');
};
