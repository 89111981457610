import { isDebugMode } from '@/utils/helpers';
import React, { useEffect, useRef } from 'react';
import { merge } from 'ts-deepmerge';
import { Address } from '../Address/Address';
import { Fee } from '../Fee/Fee';
import { Form } from '../Form/Form';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '../Form/FormTypes';
import { SplashPage } from '../SplashPage/SplashPage';
import { WizardPage } from './WizardTypes';

interface WizardProps {
  /**
   * JSON data to generate the form wizard
   */
  data: any;
  /**
   * key and values of variables to be used to repalce form variables
   */
  variables?: { name: string; value: string }[];
  /**
   * callback function that gets triggered when the form is a valid form and submit happens
   */
  onSubmit: (data: any) => void;
  /**
   * callback function that gets triggered when the wizard is unmounted (i.e. close button is clicked on the CanvasV2)
   */
  onSave?: (data: any) => void;
  /**
   * callback function that gets triggered when the close button is clicked
   */
  onClose?: () => void;
  /**
   * callback function that gets triggered when Redirect button in clicked
   * @param url url to redirect to
   * @param target internal or external url
   */
  onRedirect?: (url: any, target: any) => void;
  /**
   * boolean to show form submit loading state until external submit handler is resolved
   */
  isLoading?: boolean;
  /**
   * boolean to check if the form has a floating widget for footer or not
   */
  isFloatingWidget?: boolean;
  /**
   * Determines the colour scheme of flaoting widget
   */
  floatingWidgetRank?: string | null;
  /**
   * Determines the max-width of form wizard, does not apply to spalash pages
   */
  maxWidth?: string;
}

/**
 * Internal utility functions
 */
// flattenObject is used to flatten the formValues object
const flattenObject = (obj: any, parentKey?: string) => {
  let result = {};
  if (obj === null || typeof obj === 'undefined') {
    return result;
  }

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;
    if (Array.isArray(value)) {
      // @ts-ignore
      result[_key] = value;
    } else if (typeof value === 'object') {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      // @ts-ignore
      result[_key] = value;
    }
  });

  return result;
};

// unflattenObject is used to unflatten the formValues object
const unflattenObject = (obj: any): any => {
  const result: any = {};

  for (const key in obj) {
    const keys = key.split('.');
    keys.reduce((acc, part, index) => {
      if (index === keys.length - 1) {
        acc[part] = obj[key];
      } else {
        acc[part] = acc[part] || {};
      }
      return acc[part];
    }, result);
  }

  return result;
};

// getTriggerInputValue is used to get the value of the trigger input from current FormValues
const getTriggerInputValue = (
  triggerInput: any,
  formValues: { [key: string]: any }
) => {
  const flattenedFormValues: { [key: string]: any } = flattenObject(formValues);
  return flattenedFormValues[triggerInput];
};

// given an array of keys and an object, removeKeysFromObject removes the keys from the object
const removeKeysFromObject = (keys: string[], obj: any): any => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!keys.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as any);
};

// getWizardPagesInputNames is used to get an array of input names for each page as reference for clearing fromValues if needed
const getWizardPagesInputNames = (formPages: any[]) => {
  return formPages.map((page: any) => {
    if (page.pageComponents == null) return null;
    return page.pageComponents
      .flatMap((component: any) => component.componentInputs)
      .map((input: any) => input.name);
  });
};

// extractComponentData is used to extract component data from the formPages
const extractComponentData = (formPage: any) => {
  if (!formPage.pageComponents) return [];

  return formPage.pageComponents.flatMap((component: any) => {
    return component.componentInputs.map((input: any) => ({
      name: input.name,
      trigger:
        component.componentTrigger?.map((trigger: any) => ({
          input: trigger.input,
          value: trigger.value,
        })) || [],
    }));
  });
};

// clearTriggeredComponents is used to clear the triggered components
const clearTriggeredComponents = (
  componentData: any[],
  name: any,
  value: any,
  componentTriggerKeysToClear: string[]
) => {
  const triggerComponents = componentData.filter((component: any) =>
    component.trigger.some((trigger: any) => trigger.input === name)
  );

  if (triggerComponents.length === 0) return;

  triggerComponents.forEach((triggerComponent: any) => {
    const triggerIncludesValue = triggerComponent.trigger.some((trigger: any) =>
      trigger.value.includes(value)
    );

    if (!triggerIncludesValue) {
      componentTriggerKeysToClear.push(triggerComponent.name);
    }
  });
};

// checkIfMutated is used to check if the form values have been mutated
const checkIfMutated = (
  componentData: any[],
  currentFormData: any,
  existingFormValues: any,
  componentTriggerKeysToClear: string[]
) => {
  return componentData
    .map(({ name }: { name: string }) => {
      if (existingFormValues[name] === undefined) return false;
      if (currentFormData[name] === existingFormValues[name]) return false;
      clearTriggeredComponents(
        componentData,
        name,
        currentFormData[name],
        componentTriggerKeysToClear
      );
      return true;
    })
    .some((isMutated: boolean) => isMutated);
};

// utility function to replace form data variables
const replaceVariables = (data: any, variables: any) => {
  let dataString = JSON.stringify(data);

  variables.forEach((variable: any) => {
    const regex = new RegExp(`{{${variable.name}}}`, 'g');
    dataString = dataString.replace(regex, variable.value);
  });
  // console.log(dataString);
  return JSON.parse(dataString);
};

/**
 * Wizard component which generates a form wizard based on the JSON data passed in
 */
export const Wizard = ({
  data,
  variables,
  onSubmit,
  onSave,
  onClose,
  onRedirect,
  isLoading = false,
  isFloatingWidget = true,
  floatingWidgetRank = 'secondary',
  maxWidth = '100%',
}: WizardProps) => {
  //create an array of input names for each page as reference for clearing fromValues if needed
  const wizardPagesInputNames = getWizardPagesInputNames(data.formPages);
  const wizardFormValues =
    typeof data.formValues === 'string'
      ? JSON.parse(data.formValues)
      : data.formValues;
  // activePageIndex is used to track the current page of the wizard
  const [activePageIndex, setActivePageIndex] = React.useState(0);
  // formValues is used to track the current form values across all wizard pages
  const [formValues, setFormValues] = React.useState(wizardFormValues || {});
  // activePageIndex is used to track the current page of the wizard
  const [currentFooterAction, setCurrentFooterAction] = React.useState('');
  // isFormSubmitted is used to track if the form has been submitted
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);

  // reference to the formValues state and isFormSubmitted state to be used in the cleanup function
  const isFormSubmittedRef = useRef(isFormSubmitted);
  const formValuesRef = useRef(formValues);

  // update the ref values on every formValues and isFormSubmitted state change
  useEffect(() => {
    formValuesRef.current = formValues;
    isFormSubmittedRef.current = isFormSubmitted;
  }, [formValues, isFormSubmittedRef]);

  // cleanup function when the component is unmounted
  useEffect(() => {
    return () => {
      isDebugMode() && console.log('Wizard unmounted');
      handleSave(formValuesRef.current, isFormSubmittedRef.current);
    };
  }, []);

  // internal function to handle form submission of each page
  const handleSubmit = (formData: any, actionType: string) => {
    switch (actionType) {
      case 'next':
        handleFormValues(formData);
        setActivePageIndex((index) => index + 1);
        setCurrentFooterAction('next');
        break;
      case 'backNext':
        handleFormValues(formData);
        setActivePageIndex((index) => index + 1);
        setCurrentFooterAction('next');
        break;
      case 'submit':
      case 'backSubmit':
        handleFormValues(formData);
        onSubmit(merge(formValues, formData));
        setIsFormSubmitted(true);
        // if (data.formPages[activePageIndex + 1]?.pageType === 'splash') {
        //   setActivePageIndex((index) => index + 1);
        // } else {
        //   setActivePageIndex(0);
        //   setFormValues({});
        // }
        setActivePageIndex((index) => index + 1);
        setCurrentFooterAction('submit');
        break;
      default:
        break;
    }
  };

  const handleSave = (formValues: any, isFormSubmitted: boolean) => {
    if (!isFormSubmitted && onSave) {
      isDebugMode() && console.log('saving form progress...', formValues);
      onSave(formValues);
    }
  };

  const handleBack = () => {
    setActivePageIndex((index) => index - 1);
    setCurrentFooterAction('back');
  };

  const handleSkip = () => {
    setActivePageIndex((index) => index + 1);
    setCurrentFooterAction('next');
  };

  const handleFormValues = (formData: any) => {
    const componentTriggerKeysToClear: string[] = [];
    const componentData = extractComponentData(data.formPages[activePageIndex]);
    const currentFormData = flattenObject(formData);
    const existingFormValues = flattenObject(formValues);

    // check if the form values have been mutated
    const isMutated = checkIfMutated(
      componentData,
      currentFormData,
      existingFormValues,
      componentTriggerKeysToClear
    );

    if (isMutated) {
      // if form values have been mutated, clear the form values of the next pages
      // incuding the current page's affected components with trigger
      const keysToDelete = wizardPagesInputNames
        .slice(activePageIndex + 1)
        .flat();

      // remove the keys from the formValues
      const newFormValues = removeKeysFromObject(
        [...keysToDelete, ...componentTriggerKeysToClear],
        existingFormValues
      );

      // remove the keys from the currentFormData
      const newFormData = removeKeysFromObject(
        [...keysToDelete, ...componentTriggerKeysToClear],
        currentFormData
      );

      // merge the newFormValues and newFormData
      setFormValues((values: any) => {
        const updatedFormValues = merge(
          unflattenObject(newFormValues),
          unflattenObject(newFormData)
        );
        isDebugMode() && console.log('formValues', updatedFormValues);
        return updatedFormValues;
      });
    } else {
      // if form values have not been mutated, merge the form values as usual
      setFormValues((values: any) => {
        const updatedFormValues = merge(values, formData);
        isDebugMode() && console.log('formValues', updatedFormValues);
        return updatedFormValues;
      });
    }
  };

  // internal function to generate pages based on the pages array in the JSON data
  const generatePages = (pages: WizardPage[]) => {
    return pages.map((page: WizardPage, index: number) => {
      const pageComponents: DynamicFormComponent[] = page.pageComponents;
      const footerComponents: DynamicFooterComponent[] = page.footerComponents;
      const pageType: string = page.pageType;

      switch (pageType) {
        case 'address':
          return (
            <Address
              id={pageComponents[0].componentId}
              components={pageComponents}
              footerComponents={footerComponents}
              onSubmit={(formData) =>
                handleSubmit(
                  formData,
                  footerComponents[0].componentInputs[
                    footerComponents.length - 1
                  ].type
                )
              }
              onBack={handleBack}
              onSkip={handleSkip}
              onClose={onClose}
              onRedirect={onRedirect}
              formFields={pageComponents[0].componentInputs.map(
                (input) => input.name
              )}
              label={pageComponents[0].componentLabel}
              guidance={pageComponents[0].componentGuidance}
              formValues={formValues}
              isFloatingWidget={isFloatingWidget}
              floatingWidgetRank={floatingWidgetRank}
              key={index}
            />
          );
          break;
        // case 'logList':
        //   return (
        //     <LogList
        //       field={pageComponents[0].componentInputs[0]}
        //       footerComponents={footerComponents}
        //       onSubmit={(formData) =>
        //         handleSubmit(formData, footerComponents[0].componentInputs[footerComponents.length - 1].type)
        //       }
        //       onBack={handleBack}
        //       logListWizardData={page.innerWizard}
        //       label={pageComponents[0].componentLabel}
        //       guidance={pageComponents[0].componentGuidance}
        //     />
        //   );
        case 'splash':
          return (
            <>
              <SplashPage
                backgroundImage={page?.pageBackgroundImage}
                footerComponents={footerComponents}
                onSubmit={(formData) =>
                  handleSubmit(
                    formData,
                    footerComponents[0].componentInputs[
                      footerComponents.length - 1
                    ].type
                  )
                }
                onBack={handleBack}
                onClose={onClose}
                onRedirect={onRedirect}
                key={index}
              />
            </>
          );
        case 'feePercentage':
          return (
            <>
              <Fee
                components={pageComponents}
                footerComponents={footerComponents}
                onSubmit={(formData) =>
                  handleSubmit(
                    formData,
                    footerComponents[0].componentInputs[
                      footerComponents.length - 1
                    ].type
                  )
                }
                onBack={handleBack}
                onSkip={handleSkip}
                onClose={onClose}
                onRedirect={onRedirect}
                formFields={pageComponents[0].componentInputs.map(
                  (input) => input.name
                )}
                label={pageComponents[0].componentLabel}
                guidance={pageComponents[0].componentGuidance}
                formValues={formValues}
                type="percentage"
                key={index}
              />
            </>
          );
        case 'feeFixed':
          return (
            <>
              <Fee
                components={pageComponents}
                footerComponents={footerComponents}
                onSubmit={(formData) =>
                  handleSubmit(
                    formData,
                    footerComponents[0].componentInputs[
                      footerComponents.length - 1
                    ].type
                  )
                }
                onBack={handleBack}
                onSkip={handleSkip}
                onClose={onClose}
                onRedirect={onRedirect}
                formFields={pageComponents[0].componentInputs.map(
                  (input) => input.name
                )}
                label={pageComponents[0].componentLabel}
                guidance={pageComponents[0].componentGuidance}
                formValues={formValues}
                type="fixed"
                key={index}
              />
            </>
          );
        default:
          return (
            <Form
              components={pageComponents}
              footerComponents={footerComponents}
              onSubmit={(formData) =>
                handleSubmit(
                  formData,
                  footerComponents[0].componentInputs[
                    footerComponents.length - 1
                  ].type
                )
              }
              onBack={handleBack}
              onSkip={handleSkip}
              onClose={onClose}
              onRedirect={onRedirect}
              isFloatingWidget={isFloatingWidget}
              isLoading={isLoading}
              formValues={formValues}
              floatingWidgetRank={floatingWidgetRank}
              key={index}
            />
          );
          break;
      }
    });
  };

  const wizardData = variables ? replaceVariables(data, variables) : data;
  const pages = React.Children.toArray(generatePages(wizardData.formPages));
  const currentPage = pages[activePageIndex];
  const currentPageData = data.formPages[activePageIndex];

  /**
   * on every page change, check if the current page has a passing trigger
   * based on the current formValues state
   */
  useEffect(() => {
    const triggers = currentPageData?.pageTrigger;

    if (
      triggers &&
      triggers.length > 0 &&
      !triggers.reduce((acc: any, currentTrigger: any, index: number) => {
        // get the value of the trigger input from current FormValues
        const currentFormValueOfInput = getTriggerInputValue(
          currentTrigger.input,
          formValues
        );
        // check if the current trigger value includes the current form value of the input
        const currentTriggerIncludesValue = currentTrigger.value.includes(
          currentFormValueOfInput
        );
        // if index is greater than 0 (meaning multiple page triggers),
        // check if the previous trigger values were true AND the current trigger value is true
        if (index > 0) {
          return acc && currentTriggerIncludesValue;
        }
        // if index is 0, just return the current trigger value
        return currentTriggerIncludesValue;
      }, false)
    ) {
      setActivePageIndex((index) =>
        currentFooterAction === 'next' || currentFooterAction === 'submit'
          ? index + 1
          : index - 1
      );
    }
  }, [activePageIndex]);

  return <>{currentPage}</>;
};
