'use client';

import { Loader } from '@/components/_common/Loader/Loader';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

type Props = {
  title: string;
  subtitle?: string | null;
  content?: React.ReactNode;
  subContent?: React.ReactNode;
  backgroundImage?: any;
  footer: React.ReactNode;
  isLoading: boolean;
  isSuccess?: boolean;
  height?: string;
};

function Splash({
  title,
  subtitle = null,
  content = null,
  subContent = null,
  backgroundImage,
  footer,
  isLoading = false,
  isSuccess = false,
}: Props) {
  const theme = useTheme();
  const BACKGROUND_IMAGE = isLoading
    ? 'none'
    : backgroundImage
    ? `url(${backgroundImage.src})`
    : 'none';

  const textColor = isSuccess
    ? theme.palette.success.contrastText
    : isLoading
    ? theme.palette.primary.main
    : theme.palette.text.primary;
  const bgColor = isSuccess
    ? theme.palette.success.main
    : theme.palette.background.default;

  const Loading = () => {
    return (
      <>
        <Stack
          sx={{
            gap: '16px',
            width: { xs: '100%', md: '60%' },
          }}
        >
          <Box
            sx={{
              position: { xs: 'static', md: 'absolute' },
              bottom: '48px',
              right: '64px',
              transform: { xs: 'scale(1)', md: 'scale(2)' },
              transformOrigin: 'bottom right',
            }}
          >
            <Loader
              backgroundColor="transparent"
              mainColor={textColor}
              tickColor={textColor}
            />
          </Box>
          <Typography
            variant="h3"
            sx={{
              textAlign: { xs: 'center', md: 'left' },
            }}
            color={textColor}
          >
            {title}
          </Typography>
        </Stack>
      </>
    );
  };

  const SplashContent = () => {
    return (
      <>
        {content}
        <Stack sx={{ gap: 0.5, width: { xs: '100%', sm: '100%', md: '60%' } }}>
          <Typography
            variant="h3"
            sx={{
              textAlign: 'left',
              color: textColor,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ textAlign: 'left', color: textColor }}
          >
            {subtitle}
          </Typography>
          {subContent}
        </Stack>
      </>
    );
  };

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{
          backgroundColor: bgColor,
          backgroundImage: { sm: 'none', md: BACKGROUND_IMAGE },
          borderRadius: 2,
          flexGrow: 1,
          height: '1px',
          justifyContent: {
            xs: isLoading ? 'center' : 'flex-start',
            sm: isLoading ? 'center' : 'flex-start',
            md: 'flex-end',
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'bottom right',
          width: '100%',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            px: { xs: 4, sm: 4, md: 6 },
            py: { xs: 6, sm: 6, md: 6 },
            gap: 2,
            flexDirection: {
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'column',
            },
          }}
        >
          {isLoading ? <Loading /> : <SplashContent />}
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', alignItems: 'center' }}>
        <Stack sx={{ width: '100%', maxWidth: { sm: '100%', md: '400px' } }}>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              sx={{ borderRadius: 2 }}
              width="100%"
              height={64}
            />
          ) : (
            footer
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Splash;
