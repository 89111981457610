import FormLayout from '@/components/_layout/Form/Form';
import House from '@/public/house.svg';
import HousePlaceholder from '@/public/house_placeholder.svg';
import { AddressFinder } from '@ideal-postcodes/address-finder';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import Search from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { FormFields } from '../Form/FormFields';
import { FormFooterComponents } from '../Form/FormFooterComponents';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '../Form/FormTypes';
import './address.css';

interface AddressProps {
  /**
   * default id of the address component 'address' for non-nested, can be nested by adding a dot notation string
   */
  id?: string;
  /**
   * array of field objects used to generate the form with type of DynamicFormComponent
   * check sample json schema/data for reference can be found in ./formFieldsSampleData.ts
   */
  components: DynamicFormComponent[];
  /**
   * JSON data of type DynamicFooterComponent[] which generates the footer
   */
  footerComponents: DynamicFooterComponent[];
  /**
   * callback function that gets triggered when the form is a valid form and submit happens
   */
  onSubmit: (data: any) => void;
  /**
   * callback function that gets triggered when Back button in clicked
   */
  onBack?: () => void;
  /**
   * callback function that gets triggered when Skip button in clicked
   */
  onSkip?: () => void;
  /**
   * callback function that gets triggered when Close button in clicked
   */
  onClose?: () => void;
  /**
   * callback function that gets triggered when Redirect button in clicked
   * @param url url to redirect to
   * @param target internal or external url
   */
  onRedirect?: (url: any, target: any) => void;
  /**
   * array of form property names that you want to extract and submit as formValues
   * defaults to null which will extract all form fields
   */
  formFields?: string[] | null;
  /**
   * h3 label of the address form
   */
  label: string | null;
  /**
   * guidance for the address form
   */
  guidance?: string | null;
  /**
   * current form values of address from the wizard
   */
  formValues?: any;
  /**
   * boolean to check if the form has a floating widget for footer or not
   */
  isFloatingWidget?: boolean;
  /**
   * Determines the colour scheme of flaoting widget
   */
  floatingWidgetRank?: string | null;
}

/**
 * type for form values
 */
type FormValuesType = {
  line1: string;
  line2: string;
  town: string;
  postcode: string;
  uprn?: string;
} | null;

type FlattenedFormValuesType = {
  [key: string]: any;
};

/**
 * Internal utility functions
 */
// flattenObject is used to flatten the formValues object
const flattenObject = (obj: any, parentKey?: string) => {
  let result = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;
    if (typeof value === 'object') {
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      // @ts-ignore
      result[_key] = value;
    }
  });

  return result;
};

const isObjectEmpty = (objectName: any) => {
  return JSON.stringify(objectName) === '{}';
};

/**
 * Address component
 */
export const Address = ({
  id = 'address',
  components,
  footerComponents,
  onSubmit,
  onBack,
  onSkip,
  onClose,
  onRedirect,
  formFields = null,
  label,
  guidance,
  formValues,
}: AddressProps) => {
  const theme = useTheme();
  const [address, setAddress] = useState<FormValuesType>(null);
  const [cantFindAddress, setCantFindAddress] = useState<boolean>(false);

  const formMethods = useForm({
    mode: 'onChange',
  });
  const { errors } = formMethods.formState;

  useEffect(() => {
    // make addressLineOne input controlled by AddressFinder
    AddressFinder.setup({
      inputField: '#addressLineOne',
      apiKey: 'ak_ldf4hjhcfP5qRWWsq6KvSYZXiqjkP',
      detectCountry: false,
      defaultCountry: 'GBR',
      restrictCountries: ['GBR'],
      onAddressRetrieved: (address: any) => {
        setAddress({
          line1: address.line_1,
          line2: address.line_2,
          town: address.post_town,
          postcode: address.postcode,
          uprn: address.uprn,
        });
      },
      injectStyle: false,
    });
  }, []);

  // sets default form values from Wizard
  useEffect(() => {
    if (!isObjectEmpty(formValues)) {
      if (id === 'address') {
        setAddress({
          line1: formValues?.line1,
          line2: formValues?.line2,
          town: formValues?.town,
          postcode: formValues?.postcode,
          uprn: formValues?.uprn,
        });
      } else {
        const flattenedFormValues: FlattenedFormValuesType =
          flattenObject(formValues);
        setAddress({
          line1: flattenedFormValues[id + '.line1'],
          line2: flattenedFormValues[id + '.line2'],
          town: flattenedFormValues[id + '.town'],
          postcode: flattenedFormValues[id + '.postcode'],
          uprn: flattenedFormValues[id + '.uprn'],
        });
      }
    }
  }, []);

  // generate nested form values based on the id of the address component
  const generateFormValues = (address: any) => {
    if (id === 'address') return address;
    const keys = id;
    const nestedObject = {};
    let container = nestedObject;
    keys.split('.').map((k: string, i: number, values: string[]) => {
      container = (container as any)[k] =
        i === values.length - 1 ? address : {};
    });
    return nestedObject;
  };

  // pick form values based on formFields array of property strings based on the JSON schema component inputs
  const pickFormValues = (formValues: any) => {
    if (!formFields) return generateFormValues(formValues);
    const filteredValues = Object.assign(
      {},
      ...formFields.map((key: string) => ({ [key]: formValues[key] }))
    );
    return generateFormValues(filteredValues);
  };

  const hasErrors =
    errors.line1 || errors.line2 || errors.town || errors.postcode;

  const generateErrorMessage = (errors: any) => {
    return Object.keys(errors).map((key: string) => {
      const message = errors[key].message;
      return `${message}`;
    });
  };

  // effect runs when default values are updated, when not set it defaults to null
  useEffect(() => {
    // resets the form with default values
    formMethods.reset({ ...address });
  }, [address]);

  const generateGuidance = (text: any) => {
    // eslint-disable-next-line react/no-children-prop
    return text ? <ReactMarkdown children={text} /> : null;
  };

  const generateComponent = (component: any) => {
    return (
      <>
        {component.componentInputs.map((field: any, fieldIndex: number) => (
          <FormFields key={field.name + fieldIndex} {...field} />
        ))}
      </>
    );
  };

  const generateFooterComponent = (component: any) => {
    return (
      <>
        {component.componentInputs.map((footer: any, footerIndex: number) => (
          <Stack key={footer.name + footerIndex}>
            <FormFooterComponents
              {...footer}
              onBack={onBack}
              onSkip={onSkip}
              onClose={onClose}
              onRedirect={onRedirect}
            />
          </Stack>
        ))}
      </>
    );
  };

  const Content = (
    <Stack sx={{ justifyContent: 'center', gap: 1, width: '100%' }}>
      <Stack sx={{ gap: 1, width: '100%', px: 1 }}>
        <Typography variant="h6">{label}</Typography>
        <Typography variant="body1">{generateGuidance(guidance)}</Typography>
      </Stack>
      <Stack
        gap={1}
        width="100%"
        sx={{ display: cantFindAddress ? 'flex' : 'none' }}
      >
        {components.map((component) => generateComponent(component))}
      </Stack>
      <Paper
        elevation={0}
        sx={{
          padding: '0 8px 8px 8px',
          borderRadius: 3,
          width: '100%',
          display: cantFindAddress ? 'none' : 'flex',
        }}
      >
        <Stack sx={{ gap: 1, flexGrow: 1 }}>
          <TextField
            id="addressLineOne"
            name="addressLineOne"
            placeholder="Find address ..."
            helperText={hasErrors ? 'Address is required' : null}
            error={!!hasErrors}
            fullWidth
            label="Address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <HomeIcon />
                </InputAdornment>
              ),
            }}
            sx={{ mt: 1 }}
            data-testid={id}
          />
          <Stack direction="row" sx={{ p: 1, alignItems: 'center', gap: 2 }}>
            <Image
              src={
                !address || (address && !address.line1)
                  ? HousePlaceholder.src
                  : House.src
              }
              alt="address placeholder image"
              width={96}
              height={96}
              style={{
                borderRadius: '8px',
                border:
                  !address || (address && !address.line1)
                    ? 'none'
                    : '2px solid #ffffff',
                flexGrow: 0,
                objectFit: 'cover',
                backgroundColor: theme.palette.background.default,
              }}
            />

            {!address || (address && !address.line1) ? (
              <Stack
                sx={{
                  color: theme.palette.text.disabled,
                  flexGrow: 1,
                }}
              >
                <Typography>First line...</Typography>
                <Typography>Town</Typography>
                <Typography>Postcode...</Typography>
              </Stack>
            ) : (
              <Stack sx={{ color: theme.palette.text.primary, flexGrow: 1 }}>
                <Typography variant="body1">
                  {address ? address.line1 : 'Line one...'}
                  {address
                    ? address.line2 && (
                        <>
                          <br /> {address?.line2}
                        </>
                      )
                    : ''}
                </Typography>
                <Typography variant="body1">
                  {address ? address.town : 'Town...'}
                </Typography>
                <Typography variant="body1">
                  {address ? address.postcode : 'Postcode...'}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Paper>
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={cantFindAddress ? <ChevronLeft /> : <Search />}
        fullWidth
        onClick={() => {
          setCantFindAddress(!cantFindAddress);
        }}
      >
        {cantFindAddress ? 'Back to search' : `I can't find the address`}
      </Button>
    </Stack>
  );

  const Footer = (
    <>
      {footerComponents.map((component) => generateFooterComponent(component))}
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((formValues: any) =>
          onSubmit(pickFormValues(formValues))
        )}
        style={{ height: '100%', flexGrow: 1 }}
      >
        <FormLayout content={Content} footer={Footer}></FormLayout>
      </form>
    </FormProvider>
  );
};
