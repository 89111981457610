import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { FormFooterComponents } from '../Form/FormFooterComponents';
import { DynamicFooterComponent } from '../Form/FormTypes';

interface SplashPageProps {
  /**
   * sets background image of entire page
   */
  backgroundImage: string;
  /**
  /**
   * JSON data of type DynamicFooterComponent[] which generates the footer
   */
  footerComponents: DynamicFooterComponent[];
  /**
   * callback function that gets triggered when submit happens
   */
  onSubmit: (data: any) => void;
  /**
   * callback function that gets triggered when Back button in clicked
   */
  onBack?: () => void;
  /**
   * callback function that gets triggered when Close button in clicked
   */
  onClose?: () => void;
  /**
   * callback function that gets triggered when Redirect button in clicked
   * @param url url to redirect to
   * @param target internal or external url
   */
  onRedirect?: (url: any, target: any) => void;
}
/**
 * Creates splash page for form wizard
 */
export const SplashPage = ({
  backgroundImage,
  footerComponents,
  onSubmit,
  onBack,
  onClose,
  onRedirect,
}: SplashPageProps) => {
  // exposes the form methods to the form
  const formMethods = useForm();

  // generate guidance and convertmarkdown to html
  const generateGuidance = (text: any) => {
    // eslint-disable-next-line react/no-children-prop
    return text ? <ReactMarkdown children={text} /> : null;
  };

  const SplashContent = () => {
    return (
      <Stack
        sx={{
          gap: 0.5,
          width: { xs: '100%', sm: '100%', md: '60%' },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'left',
          }}
        >
          {footerComponents[0].componentLabel}
        </Typography>
        {generateGuidance(footerComponents[0].componentGuidance)}
      </Stack>
    );
  };
  const FooterContent = () => {
    return (
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        {footerComponents.map((component) =>
          component.componentInputs.map((footer, footerIndex) => (
            <Stack
              key={footer.name + footerIndex}
              sx={{ width: '100%', gap: 1 }}
            >
              <FormFooterComponents
                {...footer}
                onBack={onBack}
                onClose={onClose}
                onRedirect={onRedirect}
              />
            </Stack>
          ))
        )}
      </form>
    );
  };

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        sx={{
          backgroundColor: '#00a69a0a',
          borderRadius: '16px',
          flex: 1,
          height: '100%',
          width: '100% !important',
          maxWidth: '100%',
          justifyContent: {
            xs: 'flex-start',
            sm: 'flex-start',
            md: 'flex-end',
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'bottom right',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            width: 'calc(100% - 16px)',
            height: '100%',
            flex: 1,
            position: 'relative',
            borderRadius: '8px',
            margin: '8px',
            overflow: 'hidden',
            minHeight: '300px',
          }}
        >
          <Image
            src={backgroundImage}
            alt={`${footerComponents[0].componentLabel} image`}
            layout="fill"
            objectFit="cover"
          />
        </Box>
        <Stack
          sx={{
            px: { xs: 4, sm: 4, md: 6 },
            py: { xs: 6, sm: 6, md: 6 },
            gap: 2,
            flexDirection: {
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'column',
            },
          }}
        >
          {<SplashContent />}
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', alignItems: 'center', flex: 0 }}>
        <Stack sx={{ width: '100%', maxWidth: { sm: '100%', md: '400px' } }}>
          {<FooterContent />}
        </Stack>
      </Stack>
    </Stack>
  );
};
