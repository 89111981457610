import FormLayout from '@/components/_layout/Form/Form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { FormFields } from '../Form/FormFields';
import { FormFooterComponents } from '../Form/FormFooterComponents';
import {
  DynamicFooterComponent,
  DynamicFormComponent,
} from '../Form/FormTypes';

interface FeeProps {
  /**
   * array of field objects used to generate the form with type of DynamicFormComponent
   * check sample json schema/data for reference can be found in ./formFieldsSampleData.ts
   */
  components: DynamicFormComponent[];
  /**
   * JSON data of type DynamicFooterComponent[] which generates the footer
   */
  footerComponents: DynamicFooterComponent[];
  /**
   * callback function that gets triggered when the form is a valid form and submit happens
   */
  onSubmit: (data: any) => void;
  /**
   * callback function that gets triggered when Back button in clicked
   */
  onBack?: () => void;
  /**
   * callback function that gets triggered when Skip button in clicked
   */
  onSkip?: () => void;
  /**
   * callback function that gets triggered when Close button in clicked
   */
  onClose?: () => void;
  /**
   * callback function that gets triggered when Redirect button in clicked
   * @param url url to redirect to
   * @param target internal or external url
   */
  onRedirect?: (url: any, target: any) => void;
  /**
   * array of form property names that you want to extract and submit as formValues
   * defaults to null which will extract all form fields
   */
  formFields?: string[] | null;
  /**
   * h3 label of the address form
   */
  label: string | null;
  /**
   * guidance for the address form
   */
  guidance?: string | null;
  /**
   * current form values of address from the wizard
   */
  formValues?: any;
  /**
   * fixed or percentage
   */
  type: 'fixed' | 'percentage';
}

const VAT_CONSTANT = 1.2;
/**
 * Fee component
 */
export const Fee = ({
  components,
  footerComponents,
  onSubmit,
  onBack,
  onSkip,
  onClose,
  onRedirect,
  label,
  guidance,
  formValues,
  type,
}: FeeProps) => {
  const formMethods = useForm({
    mode: 'onChange',
  });
  const [fee, setFee] = useState('');

  const excludeVatFieldName = components[0].componentInputs[0].name;
  const vatFieldName = components[0].componentInputs[1].name;
  const includeVatFieldName = components[1].componentInputs[0].name;
  const feeFieldName = components[2].componentInputs[0].name;

  const generateFee = (value: any) => {
    const excluding = value[excludeVatFieldName] || 0;
    const including = excluding * VAT_CONSTANT;
    // price defaults to 100000 (meaning pricingType is pendingFormalValuation)
    // when agreedPrice or agreedPriceMax is undefined
    const price = value.agreedPrice || value.agreedPriceMax || 100000;

    const calculatedFeeExcludingVAT = (excluding * price) / 100;
    const calculatedFeeIncludingVAT = (including * price) / 100;

    const excludingPretty = excluding.toLocaleString();
    const includingPretty = including.toLocaleString();
    const pricePretty = price.toLocaleString();
    const calculatedFeeExcludingVATPretty =
      calculatedFeeExcludingVAT.toLocaleString();
    const calculatedFeeIncludingVATPretty =
      calculatedFeeIncludingVAT.toLocaleString();

    if (
      type === 'percentage' &&
      formValues?.pricingType === 'pendingFormalValuation'
    )
      return value.vat
        ? `${excludingPretty}% plus VAT (This is ${includingPretty}% inclusive of VAT
          which in the absence of a valuation would be £${calculatedFeeIncludingVATPretty} for every £${pricePretty} your property sold for).`
        : `${excludingPretty}% (In the absence of a valuation this would be £${calculatedFeeExcludingVATPretty} 
          for every £${pricePretty} your property sold for).`;

    if (type === 'percentage')
      return value.vat
        ? `${excludingPretty}% plus VAT (This is ${includingPretty}% inclusive of VAT
          which would be £${calculatedFeeIncludingVATPretty} if your property sold for
          £${pricePretty})`
        : `${excludingPretty}% (This is would be £${calculatedFeeExcludingVATPretty} 
          if your property sold for £${pricePretty})`;

    return value.vat
      ? `£${excludingPretty} plus VAT (This is £${includingPretty} inclusive of VAT)`
      : `£${excludingPretty}`;
  };

  // effect runs on init and gets the form values as default values
  useEffect(() => {
    formMethods.reset(formValues);
    if (formValues.feeDescription) setFee(formValues.feeDescription);
  }, []);

  useEffect(() => {
    const subscription = formMethods.watch((value: any, { name, type }) => {
      if (
        (name === excludeVatFieldName || name === vatFieldName) &&
        type === 'change'
      ) {
        formMethods.setValue(
          includeVatFieldName,
          value[excludeVatFieldName] * VAT_CONSTANT
        );
        formMethods.setValue(feeFieldName, generateFee(value));
        setFee(generateFee(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [formMethods]);

  const generateGuidance = (text: any) => {
    // eslint-disable-next-line react/no-children-prop
    return text ? <ReactMarkdown children={text} /> : null;
  };

  const generateComponent = (component: any) => {
    // checks if the component has a component trigger input
    if (component?.componentTrigger?.length > 0) {
      /**
       * if the component trigger input value matches the current value of the specified input
       * show the component, if not do not show
       * this utilizes the RHF's watch method to watch the value of the specified input and compare it with the component trigger input value
       * https://react-hook-form.com/api/useform/watch
       * NOTE: for now it only supports one component trigger
       */
      return (
        <>
          {component.componentTrigger[0].value.includes(
            formMethods.watch(component.componentTrigger[0].input)
          ) && (
            <Stack key={component.componentId} sx={{ gap: 1 }}>
              {component.componentInputs.map(
                (field: any, fieldIndex: number) => (
                  <FormFields
                    key={field.name + fieldIndex}
                    {...field}
                    default_value={null}
                  />
                )
              )}
            </Stack>
          )}
        </>
      );
    } else {
      // if the component does not have a component trigger input render as normal
      return (
        <Stack key={component.componentId} sx={{ gap: 1 }}>
          {component.componentInputs.map((field: any, fieldIndex: number) => (
            <FormFields key={field.name + fieldIndex} {...field} />
          ))}
        </Stack>
      );
    }
  };

  const generateFooterComponent = (component: any) => {
    return (
      <>
        {component.componentInputs.map((footer: any, footerIndex: number) => (
          <Stack key={footer.name + footerIndex}>
            <FormFooterComponents
              {...footer}
              onBack={onBack}
              onSkip={onSkip}
              onClose={onClose}
              onRedirect={onRedirect}
            />
          </Stack>
        ))}
      </>
    );
  };

  const Content = (
    <Stack gap={1}>
      <Stack gap={1}>
        <Typography variant="h6">{label}</Typography>
        <Typography variant="body1">{generateGuidance(guidance)}</Typography>
      </Stack>
      {components.map((component) => generateComponent(component))}
      <Stack gap={1}>
        <Typography variant="h6">
          How your fee will appear in your contract
        </Typography>
        <Typography variant="body1">{fee}</Typography>
      </Stack>
    </Stack>
  );

  const Footer = (
    <>
      {footerComponents.map((component) => generateFooterComponent(component))}
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((formValues: any) =>
          onSubmit(formValues)
        )}
        style={{ height: '100%', flexGrow: 1 }}
      >
        <FormLayout content={Content} footer={Footer}></FormLayout>
      </form>
    </FormProvider>
  );
};
